import { Map, OrderedSet } from 'immutable';

import IssueCollector from '../../../utils/issue-collectors';

import type { AnonymousUser, LoggedInUser } from '../../../../app/context/CurrentUserContext';

export const loginDropdownItems = Map({
  buildItems: (onClickLogin, anonymousUser?: AnonymousUser) =>
    OrderedSet([
      Map({
        content: 'Log in',
        href: anonymousUser?.anonymousUserLinks.login,
        onActivate: onClickLogin,
      }),
    ]),
});

export const mobileUserDropdownItems = Map({
  buildItems: (onClickLogout, onClickSwitchAccount, loggedInUser?: LoggedInUser) => {
    let items = OrderedSet([
      Map({
        content: 'View Profile',
        href: loggedInUser?.loggedInUserLinks?.profile,
      }),
    ]);
    items = items.add(
      Map({
        onActivate: onClickSwitchAccount,
        content: 'Switch Account',
        href: loggedInUser?.loggedInUserLinks?.switchAccount,
        showNewLozenge: true,
      })
    );
    items = items.add(
      Map({
        content: 'Manage Atlassian account',
        href: loggedInUser?.loggedInUserLinks?.manageAccount,
      })
    );
    items = items.add(
      Map({
        content: 'Manage partner account',
        href: loggedInUser?.loggedInUserLinks?.manageDeveloperSpace,
      })
    );
    items = loggedInUser?.loggedInUserLinks?.createAddon
      ? items.add(
          Map({
            content: 'Publish a new app',
            href: loggedInUser?.loggedInUserLinks?.createAddon,
          })
        )
      : items;
    items = loggedInUser?.loggedInUserLinks?.admin
      ? items.add(
          Map({
            content: 'Site admin',
            href: loggedInUser?.loggedInUserLinks.admin,
          })
        )
      : items;
    return items.add(
      Map({
        onActivate: onClickLogout,
        content: 'Log out',
        href: loggedInUser?.loggedInUserLinks?.logout,
      })
    );
  },
});

export const developerDropdownItems = Map({
  items: OrderedSet([
    Map({
      content: 'Build apps',
      href: 'https://developer.atlassian.com/platform/marketplace/getting-started/',
      target: '_blank',
    }),
    Map({
      content: 'Publish apps',
      href: 'https://developer.atlassian.com/platform/marketplace/creating-a-marketplace-listing/',
      target: '_blank',
    }),
    Map({
      content: 'Get help',
      href: 'https://developer.atlassian.com/support',
      target: '_blank',
    }),
  ]),
});

export const customerDropdownItems = Map({
  items: OrderedSet([
    Map({
      content: 'Marketplace FAQs',
      href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/licensing/marketplace',
      target: '_blank',
    }),
    Map({
      content: 'Atlassian support',
      href: 'https://support.atlassian.com',
      target: '_blank',
    }),
  ]),
});

export const helpItems = Map({
  items: OrderedSet([
    Map({
      content: 'Feedback',
      // @ts-expect-error [MC-2850] - TS2554 - Expected 1 arguments, but got 0.
      onActivate: () => (IssueCollector ? IssueCollector.showCollector() : undefined),
    }),
    Map({
      content: 'System status',
      href: 'https://status.developer.atlassian.com',
    }),
  ]),
});

export const userDropdownItemsWithSwitchProfile = {
  buildItems: (onClickLogout, onClickSwitchAccount, loggedInUser?: LoggedInUser) => {
    const marketplaceGroup = {
      heading: 'Marketplace',
      items: [
        {
          content: 'Manage partner account',
          href: loggedInUser?.loggedInUserLinks?.manageDeveloperSpace,
        },
      ],
    };
    if (loggedInUser?.loggedInUserLinks?.createAddon) {
      marketplaceGroup.items.push({
        content: 'Publish a new app',
        href: loggedInUser?.loggedInUserLinks?.createAddon,
      });
    }
    if (loggedInUser?.loggedInUserLinks?.admin) {
      marketplaceGroup.items.push({
        content: 'Site admin',
        href: loggedInUser?.loggedInUserLinks?.admin,
      });
    }
    return [
      {
        heading: 'Account',
        items: [
          {
            showProfileDetails: true,
            href: loggedInUser?.loggedInUserLinks?.profile,
          },
          {
            content: 'Switch Account',
            href: loggedInUser?.loggedInUserLinks?.switchAccount,
            showNewBadge: true,
            onActivate: onClickSwitchAccount,
          },
          {
            content: 'Manage account',
            href: loggedInUser?.loggedInUserLinks?.manageAccount,
          },
        ],
      },
      marketplaceGroup,
      {
        items: [
          {
            onActivate: onClickLogout,
            content: 'Log out',
            href: loggedInUser?.loggedInUserLinks?.logout,
          },
        ],
      },
    ] as const;
  },
} as const;
