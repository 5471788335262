import React from 'react';

export const LegacyFooter = () => (
  <footer role="contentinfo">
    <section className="logo-container">
      <a className="bottom-logo" href="https://www.atlassian.com.atl.zbizdev.zengel.myshn.net" aria-label="atlassian logo" />
      <nav>
        <ul>
          <li>
            <a href="/archive">Archived apps</a>
          </li>
          <li>
            <a href="https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/privacy-policy">Privacy</a>
          </li>
          <li>
            <a href="https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/licensing/marketplace/termsofuse">Terms of use</a>
          </li>
          <li>© {new Date().getFullYear()} Atlassian</li>
        </ul>
      </nav>
    </section>
  </footer>
);
