import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import { SubtleLink } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React from 'react';

import { useGlobalNavAnalytics } from '../../Analytics/useGlobalNavAnalytics';
import { Banner } from '../../Banner';
import { dataTestIds, FIRST_FOCUSABLE_MENU_ELEMENT, resources } from '../../constants';
import { EyebrowText } from '../../ExpandedMenus.styles';
import { ExpandedNavItemLayout } from '../../ExpandedNavItemLayout';
import { WhatsNew } from '../../WhatsNew';

const ResourcesContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: token('space.400'),
  paddingTop: token('space.200'),

  [media.above.sm]: {
    paddingTop: token('space.300'),
    gap: token('space.300'),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [media.above.md]: {
    paddingTop: 0,
    gap: token('space.400'),
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});
const Container = styled.div({
  marginBottom: token('space.100'),
  [media.above.md]: {
    marginBottom: '0',
  },
});
const ResourcesSectionLinks = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  [media.above.md]: {
    gap: token('space.150'),
  },
  listStyle: 'none',
  padding: 0,
});

const SubtleLinkStyled = styled(SubtleLink)({
  color: token('color.text.subtle'),
  width: '100%',
});

const ResourceSectionLink = styled.li({
  display: 'flex',
});

const ResourcesSection = ({
  heading,
  links,
  closeMenu,
  sectionIndex,
  focusFirstLinkUnderMenu,
}: {
  heading: string;
  links: { text: string; href: string }[];
  closeMenu: () => void;
  sectionIndex: number;
  focusFirstLinkUnderMenu?: React.RefObject<HTMLAnchorElement>;
}) => {
  const { fireNavBarLinkClickEvent } = useGlobalNavAnalytics();
  const handleResourceSectionLinkClick = (text, href) => {
    fireNavBarLinkClickEvent(text, href);
    closeMenu();
  };
  return (
    <div>
      <EyebrowText role="heading" aria-level={2}>
        {heading}
      </EyebrowText>
      <ResourcesSectionLinks>
        {links.map(({ text, href }, linkIndex) => {
          return (
            <ResourceSectionLink key={linkIndex}>
              <SubtleLinkStyled
                id={
                  sectionIndex === 0 && linkIndex === 0 ? FIRST_FOCUSABLE_MENU_ELEMENT : undefined
                }
                size="medium"
                href={href}
                target="_blank"
                key={linkIndex}
                onClick={() => handleResourceSectionLinkClick(text, href)}
                rel="noopener noreferrer"
                aria-label={`${text} open in new window`}
                ref={sectionIndex === 0 && linkIndex === 0 ? focusFirstLinkUnderMenu : null}
              >
                {text}
              </SubtleLinkStyled>
            </ResourceSectionLink>
          );
        })}
      </ResourcesSectionLinks>
    </div>
  );
};

export const Resources = ({
  closeMenu,
  focusFirstLinkUnderMenu,
}: {
  closeMenu: () => void;
  focusFirstLinkUnderMenu?: React.RefObject<HTMLAnchorElement>;
}) => {
  const { showAnnouncementBannerInResourcesTab } = useStatsig();
  const showAnnouncementBannerInResourcesTabFlag = showAnnouncementBannerInResourcesTab();
  const supportForServerBannerProps = {
    title: 'Support for Server products ends February 15, 2024',
    body: 'With end of support for our Server products fast approaching, create a winning plan for your Cloud migration with the Atlassian Migration Program.',
    cta: {
      text: 'Assess my options',
      url: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/',
    },
  };

  const primaryContent = (
    <Container>
      <ResourcesContainer>
        {resources.map(({ name, links, id }, sectionIndex) => {
          return (
            <ResourcesSection
              key={id}
              heading={name}
              links={links}
              closeMenu={closeMenu}
              sectionIndex={sectionIndex}
              focusFirstLinkUnderMenu={focusFirstLinkUnderMenu}
            />
          );
        })}
      </ResourcesContainer>
      {showAnnouncementBannerInResourcesTabFlag && (
        <Banner {...supportForServerBannerProps} closeMenu={closeMenu} />
      )}
    </Container>
  );

  return (
    <ExpandedNavItemLayout
      dataTestId={dataTestIds.EXPANDABLE_MENU__RESOURCES}
      primaryContent={primaryContent}
      secondaryContent={<WhatsNew closeMenu={closeMenu} />}
    />
  );
};
