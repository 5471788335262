let sentryInstance = null;
export function resetSentryClient() {
  sentryInstance = null;
  delete window.__MARKETPLACE_SENTRY_INSTANCE__;
}
export default function getSentryClient() {
  var _sentryInstance;
  let throwException = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  const instance = (_sentryInstance = sentryInstance) !== null && _sentryInstance !== void 0 ? _sentryInstance : window.__MARKETPLACE_SENTRY_INSTANCE__;
  if (!instance && throwException) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('Called sentry method without first calling `init`');
    } else {
      console.error('Called sentry method without first calling `init`');
    }
  }
  return instance;
}

// Option to attachToWindow is a short term solution for this problem in EMCEE:
// https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/~574514075/pages/2781368024/Duplicate+packages+within+EMCEE+Confluence
export function setSentryClient(client) {
  let attachToWindow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (attachToWindow) {
    window.__MARKETPLACE_SENTRY_INSTANCE__ = client;
    return window.__MARKETPLACE_SENTRY_INSTANCE__;
  }
  sentryInstance = client;
  return sentryInstance;
}