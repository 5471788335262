import { CompatibleProductId, CompatibleProductName } from '@atlassian/mpac-types';

import administrativeTools from '../../../assets/images/administrative-tools.svg';
import bamboo from '../../../assets/images/bamboo.svg';
import bitbucket from '../../../assets/images/bitbucket.svg';
import compass from '../../../assets/images/compass.svg';
import confluence from '../../../assets/images/confluence.svg';
import contentAndCommunication from '../../../assets/images/content-and-communication.svg';
import crowd from '../../../assets/images/crowd.svg';
import crucible from '../../../assets/images/crucible.svg';
import dataAndAnalytics from '../../../assets/images/data-and-analytics.svg';
import designAndDiagramming from '../../../assets/images/design-and-diagramming.svg';
import fisheye from '../../../assets/images/fisheye.svg';
import hrAndTeamBuilding from '../../../assets/images/hr-and-team-wellbeing.svg';
import itSupportAndService from '../../../assets/images/it-support-and-service.svg';
import jiraServiceManagement from '../../../assets/images/jira-service-management.svg';
import jiraSoftware from '../../../assets/images/jira-software.svg';
import projectManagement from '../../../assets/images/project-Management.svg';
import salesAndCustomerRelations from '../../../assets/images/sales-and-customer-relations.svg';
import securityAndCompliance from '../../../assets/images/security-and-compliance.svg';
import softwareDevelopment from '../../../assets/images/software-development.svg';

export const products = [
  {
    id: CompatibleProductId.Jira,
    name: CompatibleProductName[CompatibleProductId.Jira],
    icon: jiraSoftware,
    description:
      'Apps that transform Jira into a tightly integrated holistic solution to get more done in less time.',
  },
  {
    id: CompatibleProductId.Confluence,
    name: CompatibleProductName[CompatibleProductId.Confluence],
    icon: confluence,
    description:
      'Enhance collaboration with apps that customize and extend Confluence to accomplish more together.',
  },
  {
    id: CompatibleProductId.JiraServiceManagement,
    name: CompatibleProductName[CompatibleProductId.JiraServiceManagement],
    icon: jiraServiceManagement,
    description:
      'Supercharge your Jira Service Management with apps to bring an efficient and human approach to service.',
  },
  {
    id: CompatibleProductId.COMPASS,
    name: CompatibleProductName[CompatibleProductId.COMPASS],
    icon: compass,
    description:
      'Apps that extend the capabilities of Compass to scale engineering practices and ensure system reliability.',
  },
  {
    id: CompatibleProductId.Bitbucket,
    name: CompatibleProductName[CompatibleProductId.Bitbucket],
    icon: bitbucket,
    description:
      'Apps to broaden the reach and power of Bitbucket and augment the way you collaborate on code.',
  },
  {
    id: CompatibleProductId.Bamboo,
    name: CompatibleProductName[CompatibleProductId.Bamboo],
    icon: bamboo,
    description:
      'Connect with apps that leverage the full potential of Bamboo to build, test, and deploy with confidence.',
  },
  {
    id: CompatibleProductId.Crowd,
    name: CompatibleProductName[CompatibleProductId.Crowd],
    icon: crowd,
    description:
      'Engage in effective identity management with apps that enable dynamic user management and authentication.',
  },
  {
    id: CompatibleProductId.Fisheye,
    name: CompatibleProductName[CompatibleProductId.Fisheye],
    icon: fisheye,
    description:
      'Visualize and report code changes effectively with apps to code smarter and ship faster.',
  },
  {
    id: CompatibleProductId.Crucible,
    name: CompatibleProductName[CompatibleProductId.Crucible],
    icon: crucible,
    description:
      'Apps that elevate collaborative code review and enable competent tracking and sharing of knowledge.',
  },
] as const;

export const categories = [
  {
    id: 'project-management',
    name: 'Project management',
    icon: projectManagement,
    description: 'Streamline, manage, and track project phases',
  },
  {
    id: 'admin-tools',
    name: 'Administrative tools',
    icon: administrativeTools,
    description: `Manage and configure Atlassian products to meet organization's needs`,
  },
  {
    id: 'content-communication',
    name: 'Content and communication',
    icon: contentAndCommunication,
    description: 'Create, collaborate, communicate, and promote with content',
  },
  {
    id: 'data-analytics',
    name: 'Data and analytics',
    icon: dataAndAnalytics,
    description: 'Data management and analysis for reports',
  },
  {
    id: 'software-development',
    name: 'Software development',
    icon: softwareDevelopment,
    description: 'Develop, test, and deploy software applications',
  },
  {
    id: 'it-support',
    name: 'IT support and service',
    icon: itSupportAndService,
    description: 'Moderate help and improve service management',
  },
  {
    id: 'design-diagramming',
    name: 'Design and diagramming',
    icon: designAndDiagramming,
    description: 'Curate and visualize art, graphics, and digital design',
  },
  {
    id: 'security-compliance',
    name: 'Security and compliance',
    icon: securityAndCompliance,
    description: 'Align with compliance requirements and security practices',
  },
  {
    id: 'hr-team',
    name: 'HR and team building',
    icon: hrAndTeamBuilding,
    description: 'Onboard and motivate employee productivity',
  },
  {
    id: 'sales-customer-relations',
    name: 'Sales and customer relations',
    icon: salesAndCustomerRelations,
    description: 'Streamline sales and manage customer support interactions',
  },
];

export const resources = [
  {
    id: 'learn-and-explore',
    name: 'Learn and explore',
    links: [
      {
        text: 'About Marketplace',
        href: 'https://developer.atlassian.com/platform/marketplace/',
      },
      {
        text: 'Marketplace trust programs',
        href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/trust/marketplace?source=app-tile-badge',
      },
      {
        text: 'Marketplace partner program',
        href: 'https://developer.atlassian.com/platform/marketplace/marketplace-partner-program/',
      },
      {
        text: 'Atlassian migration program',
        href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/migration',
      },
      {
        text: 'Explore Atlassian products',
        href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/',
      },
    ],
  },
  {
    id: 'build-and-list',
    name: 'Build and list',
    links: [
      {
        text: 'How to become a Marketplace partner',
        href: 'https://developer.atlassian.com/platform/marketplace/become-a-partner/',
      },
      {
        text: 'How to build apps',
        href: 'https://developer.atlassian.com/platform/marketplace/getting-started/',
      },
      {
        text: 'Create your own listing',
        href: 'https://developer.atlassian.com/platform/marketplace/creating-a-marketplace-listing/',
      },
      {
        text: 'Selling and licensing your app',
        href: 'https://developer.atlassian.com/platform/marketplace/selling-on-marketplace/',
      },
      {
        text: 'Access all partner resources',
        href: 'https://atlassianpartners.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/resources/overview?mode=global',
      },
    ],
  },
  {
    id: 'help-and-support',
    name: 'Help and support',
    links: [
      {
        text: 'Frequently asked questions',
        href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/licensing/marketplace#general-faqs',
      },
      {
        text: 'Atlassian support',
        href: 'https://support.atlassian.com/',
      },
      {
        text: 'Atlassian community',
        href: 'https://community.atlassian.com/',
      },
      {
        text: 'Developer support',
        href: 'https://developer.atlassian.com/developer-guide/help-and-feedback/',
      },
      {
        text: 'System status',
        href: 'https://developer.status.atlassian.com/',
      },
    ],
  },
];

export const helpFullLinks = {
  exploreAtlassianProducts: {
    text: 'Explore Atlassian products',
    href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/software',
  },
  learnAboutMarketplace: {
    text: 'Learn about Marketplace',
    href: 'https://developer.atlassian.com/platform/marketplace/',
  },
  getSupport: {
    text: 'Get support',
    href: 'https://support.atlassian.com/',
  },
};

export const helpMenuLinks = [
  {
    href: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/licensing/marketplace#general-faqs',
    text: 'Frequently asked questions',
  },
  {
    href: 'https://support.atlassian.com/',
    text: 'Get support',
  },
  {
    href: 'https://developer.atlassian.com/platform/marketplace/getting-started/',
    text: 'Get started',
  },
];

export const dataTestIds = {
  EXPANDABLE_MENU__ITEM: 'expandable-menu__item',
  EXPANDABLE_MENU__APPS_FOR_PRODUCTS: 'expandable-menu__apps-for-products',
  EXPANDABLE_MENU__CATEGORIES: 'expandable-menu__categories',
  EXPANDABLE_MENU__RESOURCES: 'expandable-menu__resources',
  EXPANDABLE_MENU__MOBILE_VIEW: 'expandable-menu__mobile-view',
  EXPANDABLE_MENU__BLANKET: 'expandable-menu__blanket',

  USER_DROPDOWN: 'global-nav__user-dropdown',
  USER_DROPDOWN__USER_PROFILE: 'user-dropdown__user-profile',
  HELP_DROPDOWN: 'global-nav__help-dropdown',
  SEARCH: 'global-nav__search',
  PARTNER_QUICK_LINKS: 'global-nav__partner-quick-links',
};

export const FIRST_FOCUSABLE_MENU_ELEMENT = 'global-nav__menu-first-focusable-element';
export const LAST_FOCUSABLE_MENU_ELEMENT = 'global-nav__menu-last-focusable-element';

export const USER_DROPDOWN_ITEMS = {
  SWITCH_ACCOUNT: 'Switch account',
  MANAGE_ACCOUNT: 'Manage account',
  MANAGE_PARTNER_ACCOUNT: 'Manage partner account',
  PUBLISH_NEW_APP: 'Publish a new app',
  SITE_ADMIN: 'Site admin',
  SIGN_OUT: 'Sign out',
};
